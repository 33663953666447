import React, { useEffect, useState } from 'react';

import styled, { css } from 'styled-components';

import { GridElem } from '../../common/grid';

import { View } from './view';

import { Spacing } from '../../theme';
import { THEME_ENUM } from '../../data/theme/constant';

import noteBordIcon from '../../asset/svg/menu-app/notebord.svg';
import noteBordActiveIcon from '../../asset/svg/menu-app/notebord-active.svg';

import botsIcon from '../../asset/svg/menu-app/bots.svg';
import botsActiveIcon from '../../asset/svg/menu-app/bots-active.svg';

import baseIcon from '../../asset/svg/menu-app/base.svg';
import baseActiveIcon from '../../asset/svg/menu-app/base-active.svg';

import settingIcon from '../../asset/svg/menu-app/setting.svg';
import settingActiveIcon from '../../asset/svg/menu-app/setting-active.svg';

import userIcon from '../../asset/svg/menu-app/user.svg';
import userActiveIcon from '../../asset/svg/menu-app/user-active.svg';

import paymentIcon from '../../asset/svg/menu-app/payment.svg';
import paymentActiveIcon from '../../asset/svg/menu-app/payment-active.svg';

import calendarIcon from '../../asset/svg/menu-app/calendar.svg';
import calendarIconActive from '../../asset/svg/menu-app/calendar-active.svg';

import { SETTINGS_PAGE_PATH } from '../../page/settings';
import { COLOR_ENUM } from '../../theme/color';
import { AccountSettingsLogoutContainer } from '../account-settings-logout';

import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
} from '../../theme/size';
import { ACCOUNT_UPDATE_PAGE_PATH } from '../../page/account-profile-update';
import { PSYCHO_LIST_PAGE_PATH } from '../../page/psycho-list';
import { PAYMENT_PAGE_PATH } from '../../page/payment';
import { USER_PAGE_PATH } from '../../page/user';
import { AUTH_MODULE_NAME } from '../../data/auth';
import { STORE_INTER, USER_ROLE } from '../../data/auth/constant';
import { useSelector } from '../../lib/store';
import { AccountNotificationContainer } from '../account-notification';
import { SCHEDULE_PAGE_PATH } from '../../page/schedule';

export const Component: React.FC<{
  isActivePath: Function;
  theme: THEME_ENUM;
  pathname: any;
}> = ({ isActivePath, theme }) => {
  const state: STORE_INTER = useSelector((s) => s[AUTH_MODULE_NAME]);

  const isAdmin = state.user?.role === USER_ROLE.ADMIN;

  return (
    <Container>
      <Content spacing={0}>
        <IconContainer>
          <LogoSvg src="./assets/svg/header/pets.svg" alt="pet Logo" />
        </IconContainer>

        <SettingsContainer>
          {!isAdmin && (
            <>
              <View
                href={ACCOUNT_UPDATE_PAGE_PATH}
                title="NAVIGATION.TAB_APP.QUESTIONARY"
                icon={noteBordIcon}
                iconActive={noteBordActiveIcon}
                isActive={isActivePath(ACCOUNT_UPDATE_PAGE_PATH)}
              />
              <View
                href={PSYCHO_LIST_PAGE_PATH}
                title="NAVIGATION.TAB_APP.BOTS"
                icon={botsIcon}
                iconActive={botsActiveIcon}
                isActive={isActivePath(PSYCHO_LIST_PAGE_PATH)}
              />
              <View
                href={'#'}
                title="NAVIGATION.TAB_APP.BASE"
                icon={baseIcon}
                iconActive={baseActiveIcon}
                isActive={isActivePath(SETTINGS_PAGE_PATH)}
              />

              <View
                href={SCHEDULE_PAGE_PATH}
                title="NAVIGATION.TAB_APP.SCHEDULE"
                icon={calendarIcon}
                iconActive={calendarIconActive}
                isActive={isActivePath(SCHEDULE_PAGE_PATH)}
              />
            </>
          )}

          {isAdmin && (
            <>
              <View
                href={PAYMENT_PAGE_PATH}
                title="NAVIGATION.TAB_APP.PAYMENT"
                icon={paymentIcon}
                iconActive={paymentActiveIcon}
                isActive={isActivePath(PAYMENT_PAGE_PATH)}
              />
              <View
                href={USER_PAGE_PATH}
                title="NAVIGATION.TAB_APP.USER"
                icon={userIcon}
                iconActive={userActiveIcon}
                isActive={isActivePath(USER_PAGE_PATH)}
              />
            </>
          )}

          <>
            <AccountNotificationContainer />
            <AccountSettingsLogoutContainer />
          </>
        </SettingsContainer>
      </Content>
    </Container>
  );
};

const LogoSvg = styled.img`
  width: 129px;
  height: 46px;
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-top: ${Spacing(14)};
`;

const Content = styled(GridElem)`
  width: 270px;
  background: ${({ theme }) => theme[COLOR_ENUM.WHITE]};

  grid-template-rows: min-content auto min-content;
  height: calc(100vh - 24px); // minus margin
  position: fixed;
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.MENU]}px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
`;

const SettingsContainer = styled.div`
  padding: ${Spacing(12)} ${Spacing(7)} ${Spacing(6)};
  height: 100%;
  flex-direction: column;
  display: flex;
  gap: ${Spacing(1)};
`;

// const ThemeContainer = styled.div`
//   padding-left: ${Spacing(3)};
// `;

// const PageContainer = styled(GridElem)`
//   padding: 54px ${Spacing(7)} ${Spacing(6)};
//   height: 100%;
//   align-content: space-between;
// `;

const Container = styled.div`
  grid-row: 1/3;
  margin: 12px;
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.MENU]};
`;
