import {
  GENDER_ENUM,
  REQUEST_ENUM,
  METHOD_ENUM,
} from '../../data/user/constant';

export const MODULE_NAME = 'PSYCHO_LIST_MODULE_NAME';

export const ACTION_TYPE = {
  REQUEST_PENDING: `${MODULE_NAME}.REQUEST_PENDING`,
};

export interface PSYCHO_ITEM_DATA_RAW_INTER {
  name: string;
  aboutMe?: string | null;
  age?: string | null;
  gender?: GENDER_ENUM | null;
  requestList: REQUEST_ENUM[];
  methodList?: METHOD_ENUM[] | null;
  lgbtFrendly: boolean;
  timezone: number;
  id: string;
  photo: {
    name: string;
    id: string;
    url: string;
  };
}

export interface PSYCHO_ITEM_LIST_DATA_RAW_INTER {
  list: PSYCHO_ITEM_DATA_RAW_INTER[];
  isEmpty: boolean;
}

export interface ACTION_RESPONSE_INTER
  extends PSYCHO_ITEM_LIST_DATA_RAW_INTER {}

export interface PSYCHO_ITEM_DATA_INTER
  extends Omit<PSYCHO_ITEM_DATA_RAW_INTER, 'timezone'> {
  timezone: string;
}

export interface DATA_INTER {
  list: PSYCHO_ITEM_DATA_INTER[];
  isEmpty: boolean;
}

export interface filterOption {
  psychoCategory: string;
  methodList: string;
  requestList: string;
}

export interface ACTION_ERROR_INTER {
  error: string;
  message: string;
  messages: string[];
  statusCode: string;
}

export const API = {
  TYPE: 'GET',
  URL: (params: string) => `user/psycho/list${params}`,
};
