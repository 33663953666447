import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';

import { Spacing } from '../../../theme';
import { CATEGORY_INTER } from '../constant';
import { COLOR_ENUM } from '../../../theme/color';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
} from '../../../theme/size';
import { TextElem } from '../../../common/text';
import {
  VALUE_TRANSITION_DATA,
  VALUE_TRANSITION_ENUM,
  VALUE_OPACITY_DATA,
  VALUE_OPACITY_ENUM,
} from '../../../theme/value';

import uploadDocumentSvg from '../../../asset/svg/common/document-upload.svg';
import { ButtonElem } from '../../../common/button';
import arrowSvg from '../../../asset/svg/button/arrow-down.svg';
import arrowExpandedSvg from '../../../asset/svg/button/arrow-down-expanded.svg';
import { useHistory } from 'react-router';
import { ReadMoreElem } from '../../../common/read-more';

export const Category: React.FC<{ data?: CATEGORY_INTER }> = ({ data }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isReadMore, setIsReadMore] = useState(false);

  const contentRef = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState(0);

  const openFile = (url: string) => {
    window.open(url, '_blank');
  };

  const handleContentClick = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  useEffect(() => {
    if (contentRef.current) {
      setHeight(isExpanded ? contentRef.current.scrollHeight : 0);
    }
  }, [isExpanded, isReadMore]);

  const href = `/order/${data?.id}`;
  const history = useHistory();

  const onClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    if (event.button === 0 && !event.ctrlKey && !event.metaKey) {
      event.preventDefault();
      history.push(href);
    }
  };

  const isCategoryDot =
    data?.description && data?.description.length > 220 ? '...' : '';

  return (
    <Container onClick={toggleExpand} isExpanded={isExpanded}>
      <Head>
        <TextElem
          type="medium"
          color="textPrimary"
          size="main"
          tid={String(data?.category)}
        />
        <PriceContainer>
          <TextContainer>
            <TextElem
              oneLine
              tid="USER.PSYCHO.HEADER.PRICE"
              tvalue={{ value: data?.price }}
              type="bold"
              size="main"
            />
            <TextElem
              oneLine
              type="regular"
              color="textSecondary"
              tid="USER.PSYCHO.HEADER.TIME"
              tvalue={{ value: data?.duration }}
            />
          </TextContainer>
          {!isExpanded && (
            <ButtonDisabled
              type="save"
              tid="Забронювати"
              onClick={(e: any) => {
                e.preventDefault();
                e.stopPropagation();
                onClick(e);
              }}
            />
          )}
          {isExpanded && (
            <ButtonElemStyled
              onClick={onClick}
              padding="16px 24px"
              color="default"
              tid="Забронювати"
            />
          )}
          <ArrowImg
            isExpanded={isExpanded}
            src={isExpanded ? arrowExpandedSvg : arrowSvg}
            alt="arrow"
          />
        </PriceContainer>
      </Head>
      <ContentWrapper ref={contentRef} height={height}>
        <Content>
          <VideoPreviewContainer>
            <VideoStyled controls>
              <source src={data?.video?.url} type="video/mp4" />
              Your browser does not support the video tag.
            </VideoStyled>
          </VideoPreviewContainer>
          <Info>
            <ReadMoreElem
              onToggle={() => setIsReadMore((prev) => !prev)}
              textLimit={125}
              text={data?.description || ''}
            />

            <FileWrapper>
              <FileContainer>
                <TextElem
                  onClick={handleContentClick}
                  type="medium"
                  color="textPrimary"
                  size="main"
                  tid="USER.PSYCHO.PROFILE.DIPLOMA"
                />
                <FileList>
                  {data?.certificate &&
                    data?.certificate.map((e) => (
                      <FilePreview
                        key={e.id}
                        onClick={(event) => {
                          event.stopPropagation();
                          openFile(e.url);
                        }}
                      >
                        <img src={uploadDocumentSvg} alt="diploma" />
                      </FilePreview>
                    ))}
                </FileList>
              </FileContainer>
              <FileContainer>
                <TextElem
                  onClick={handleContentClick}
                  type="medium"
                  color="textPrimary"
                  size="main"
                  tid="USER.PSYCHO.PROFILE.CERTIFICATE"
                />
                <FileList>
                  {data?.diploma &&
                    data?.diploma.map((e) => (
                      <FilePreview
                        key={e.id}
                        onClick={(event) => {
                          event.stopPropagation();
                          openFile(e.url);
                        }}
                      >
                        <img src={uploadDocumentSvg} alt="diploma" />
                      </FilePreview>
                    ))}
                </FileList>
              </FileContainer>
            </FileWrapper>
          </Info>
        </Content>
      </ContentWrapper>
    </Container>
  );
};

const ArrowImg = styled.img<{ isExpanded: boolean }>`
  width: 20px;
  height: 20px;
  margin-left: ${Spacing(2)};
  transition: transform 0.3s ease;
  transform: ${({ isExpanded }) =>
    isExpanded ? 'rotate(180deg)' : 'rotate(0deg)'};
`;

const ButtonElemStyled = styled(ButtonElem)`
  border-radius: ${SIZE_BORDER_RADIUS_DATA[
    SIZE_BORDER_RADIUS_ENUM.DEFAULT
  ]}px !important;
  padding: 16px 24px !important;
  height: 46px;
  width: 147px;

  span {
    font-size: 13px !important;
    line-height: 1em !important;
  }
`;

const ButtonDisabled = styled(ButtonElem)`
  padding: 16px 24px !important;
  border-radius: ${SIZE_BORDER_RADIUS_DATA[
    SIZE_BORDER_RADIUS_ENUM.DEFAULT
  ]}px !important;
  height: 46px;
  width: 147px;

  span {
    font-size: 13px !important;
    line-height: 1em !important;
  }
`;

const Head = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 45px;
`;

const TextContainer = styled.div`
  display: flex;
  gap: ${Spacing(2)};
  align-items: center;
`;

const PriceContainer = styled.div`
  display: flex;
  align-items: center;
  border-radius: ${SIZE_BORDER_RADIUS_DATA[
    SIZE_BORDER_RADIUS_ENUM.PROFILE_PHOTO
  ]}px;
  gap: ${Spacing(4)};
  background-color: ${({ theme }) => theme[COLOR_ENUM.WHITE]};
`;

const VideoStyled = styled.video`
  border-radius: ${SIZE_BORDER_RADIUS_DATA[
    SIZE_BORDER_RADIUS_ENUM.DROPDOWN
  ]}px !important;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
`;

const VideoPreviewContainer = styled.div`
  max-width: 341px;
  width: 100%;
  height: 190px;
  display: flex;
  align-items: center;
  justify-content: center;

  transition: ${VALUE_TRANSITION_DATA[VALUE_TRANSITION_ENUM.HOVER]};
  cursor: pointer;

  :hover {
    opacity: ${VALUE_OPACITY_DATA[VALUE_OPACITY_ENUM.HOVER]};
  }
`;

const FilePreview = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.CARD]}px;
  width: 98px;
  height: 70px;
  background-color: ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_PRIMARY]};

  transition: ${VALUE_TRANSITION_DATA[VALUE_TRANSITION_ENUM.HOVER]};
  cursor: pointer;

  :hover {
    opacity: ${VALUE_OPACITY_DATA[VALUE_OPACITY_ENUM.HOVER]};
  }
`;

const FileList = styled.div`
  display: flex;
  gap: ${Spacing(2)};
  flex-wrap: wrap;
`;

const FileContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Spacing(3)};
`;

const FileWrapper = styled.div`
  display: grid;
  grid-gap: ${Spacing(6)};
  grid-template-columns: 1fr 1fr;
  width: 100%;
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Spacing(5)};
  width: 100%;
`;

const Content = styled.div`
  display: flex;
  gap: ${Spacing(6)};
`;

const ContentWrapper = styled.div<{ height: number }>`
  height: ${(props) => props.height}px;
  overflow: hidden;
  transition: all 0.25s ease;
  position: relative;
  margin-top: ${({ height }) => height !== 0 && Spacing(5)};
`;

const Container = styled.div<{ isExpanded: boolean }>`
  width: 100%;
  border-radius: ${SIZE_BORDER_RADIUS_DATA[
    SIZE_BORDER_RADIUS_ENUM.PROFILE_PHOTO
  ]}px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  padding: ${Spacing(6)};
  background-color: ${({ theme }) => theme[COLOR_ENUM.WHITE]};
`;
