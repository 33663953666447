import { OPTION_INTER, USER_ITEM_DATA_RAW_INTER } from '../../data/user/constant';
import { i18n } from '../../lib/lang';
import { CATEGORY_ENUM } from '../profile-psycho-create-category/constant';

export const MODULE_NAME = 'PROFILE_USER_UPDATE_DATA_MODULE_NAME';
export const MODULE_DATA_NAME = 'PROFILE_USER_DATA_MODULE_NAME'; // На перенос в соответсвующий модуль
export const ACTION_TYPE = {
  REQUEST_PENDING: `${MODULE_NAME}.REQUEST_PENDING`,
};

export interface USER_DATA_RAW extends Omit<USER_ITEM_DATA_RAW_INTER, 'id' | 'createDate'| 'email'| 'role'| 'password'>{}

export enum FORM_VALUE_ENUM {
  NAME = 'name',
  PHONE = 'phone',
  AGE = 'age',
  GENDER = 'gender',
  PHOTO = 'photo',
  TIMEZONE = 'timezone',
  CITY = 'city',
  COUNTRY = 'country',
  LANGUAGE = 'language',
  // REQUEST_LIST = 'requestList',
  // REQUEST_CUSTOM = 'requestCustom',
  // LGBT_FRENDLY = 'lgbtFrendly',
  // METHOD_LIST = 'methodList',
  // METHOD_CUSTOM = 'methodCustom',
  // ABOUT = 'about',
  // PSYCHO_CATEGORY = 'psychoCategory',

  CONTACT_INFO = 'contactInfo',

  //ПРОФІЛЬ ТВАРИНИ
  ANIMAL_NAME = 'animalName',
  ANIMAL_GENDER = 'animalGender',
  ANIMAL_AGE = 'animalAge',
  ANIMAL_TYPE = 'animalType',
  ANIMAL_SIZE = 'animalSize',
  ANIMAL_PHOTO = 'animalPhoto',
  ANIMAL_PASSPORT_FILE = 'animalPassportFile',

  //ВИМОГИ ДО ДОГЛЯДАЧА
  NEED_GENDER = 'needGender',
  NEED_AGE = 'needAge',
  NEED_PLACEMENT = 'needPlacement',
  NEED_LANGUAGE = 'needLanguage',
  NEED_PREVIOUS_EXPERIENCE = 'needPreviousExperience',
  NEED_SPECIALIST = 'specialistNeeded',
  NEED_COMPATIBILITY = 'needCompatibility',

  //ЗАУВАЖЕННЯ ПО ТВАРИНІ
  COMPATIBILITY_ANIMAL = 'compatibilityAnimal',
  IMPORTANT_HABITS = 'importantHabits',
  CONCERNS = 'concerns',
  DIET = 'diet',
  REQUIRE_CARE = 'requireCare',
  MEDICATION = 'medication',
  VACCINATION = 'vaccination',
}

export type FORM_VALUE_TYPE = `${FORM_VALUE_ENUM}`;

export interface FORM_VALUE_INTER {
  [FORM_VALUE_ENUM.NAME]: string;
  [FORM_VALUE_ENUM.PHONE]: string;
  [FORM_VALUE_ENUM.AGE]: number | null;
  [FORM_VALUE_ENUM.GENDER]: OPTION_INTER | null;
  [FORM_VALUE_ENUM.TIMEZONE]: string | number;
  [FORM_VALUE_ENUM.LANGUAGE]: OPTION_INTER[];
  [FORM_VALUE_ENUM.CITY]: string;
  [FORM_VALUE_ENUM.COUNTRY]: string;
  [FORM_VALUE_ENUM.CONTACT_INFO]: string;

  [FORM_VALUE_ENUM.ANIMAL_NAME]: string;
  [FORM_VALUE_ENUM.ANIMAL_GENDER]: string;
  [FORM_VALUE_ENUM.ANIMAL_AGE]: number | null;
  [FORM_VALUE_ENUM.ANIMAL_SIZE]: OPTION_INTER | null;
  [FORM_VALUE_ENUM.ANIMAL_TYPE]: OPTION_INTER | null;

  [FORM_VALUE_ENUM.NEED_GENDER]: OPTION_INTER | null;
  [FORM_VALUE_ENUM.NEED_AGE]: number | null;
  [FORM_VALUE_ENUM.NEED_PLACEMENT]: OPTION_INTER | null;
  [FORM_VALUE_ENUM.NEED_LANGUAGE]: OPTION_INTER[];
  [FORM_VALUE_ENUM.NEED_PREVIOUS_EXPERIENCE]: string | null;
  [FORM_VALUE_ENUM.NEED_SPECIALIST]: OPTION_INTER[];
  [FORM_VALUE_ENUM.NEED_COMPATIBILITY]: string | null;

  [FORM_VALUE_ENUM.COMPATIBILITY_ANIMAL]: string | null;
  [FORM_VALUE_ENUM.IMPORTANT_HABITS]: string;
  [FORM_VALUE_ENUM.CONCERNS]: string;
  [FORM_VALUE_ENUM.DIET]: string;
  [FORM_VALUE_ENUM.REQUIRE_CARE]: string;
  [FORM_VALUE_ENUM.MEDICATION]: string;
  [FORM_VALUE_ENUM.VACCINATION]: OPTION_INTER[];

  // [FORM_VALUE_ENUM.REQUEST_LIST]: OPTION_INTER[];
  // [FORM_VALUE_ENUM.REQUEST_CUSTOM]: string;
  // [FORM_VALUE_ENUM.LGBT_FRENDLY]: string | null;
  // [FORM_VALUE_ENUM.METHOD_LIST]: OPTION_INTER[];
  // [FORM_VALUE_ENUM.METHOD_CUSTOM]: string;
  // [FORM_VALUE_ENUM.NEED_GENDER]: number | null;
  // [FORM_VALUE_ENUM.NEED_AGE]: string | null;
  // [FORM_VALUE_ENUM.ABOUT]: string;
  // [FORM_VALUE_ENUM.PSYCHO_CATEGORY]: string | CATEGORY_ENUM;
}

export const GENDER_OPTION_LIST = [
  {
    label: i18n.t('USER.USER.SELECT.MALE'),
    value: 1,
  },
  {
    label: i18n.t('USER.USER.SELECT.FEMALE'),
    value: 2,
  },
];
export const LANGUAGE_OPTION_LIST = [
  {
    label: i18n.t('USER.USER.SELECT.LANGUAGE.UA'),
    value: 'UA',
  },
  {
    label: i18n.t('USER.USER.SELECT.LANGUAGE.ENG'),
    value: 'ENG',
  },
  {
    label: i18n.t('USER.USER.SELECT.LANGUAGE.POL'),
    value: 'POL',
  },
];
export const NEED_PLACEMENT_OPTION_LIST = [
  {
    label: i18n.t('USER.USER.SELECT.PLACEMENT.OWNER'),
    value: 'owner',
  },
  {
    label: i18n.t('USER.USER.SELECT.PLACEMENT.CARETAKER'),
    value: 'caretaker',
  },
];
export const SPECIALIST_OPTION_LIST = [
  {
    label: i18n.t('USER.USER.SELECT.SPECIALIST.ANGRY'),
    value: 'angry',
  },
  {
    label: i18n.t('USER.USER.SELECT.SPECIALIST.BLIND'),
    value: 'blind',
  },
  {
    label: i18n.t('USER.USER.SELECT.SPECIALIST.DEAF'),
    value: 'deaf',
  },
];
export const ANIMAL_SIZE_OPTION_LIST = [
  {
    label: i18n.t('USER.USER.SELECT.ANIMAL_SIZE.MIDGET'),
    value: 'midget',
  },
  {
    label: i18n.t('USER.USER.SELECT.ANIMAL_SIZE.SMALL'),
    value: 'small',
  },
  {
    label: i18n.t('USER.USER.SELECT.ANIMAL_SIZE.MEDIUM'),
    value: 'medium',
  },
  {
    label: i18n.t('USER.USER.SELECT.ANIMAL_SIZE.BIG'),
    value: 'big',
  },
];

export const VACCINATION_OPTION_LIST = [
  {
    label: i18n.t('USER.USER.SELECT.VACCINATION.YES'),
    value: 'yes',
  },
  {
    label: i18n.t('USER.USER.SELECT.VACCINATION.NO'),
    value: 'no',
  },
];
export const YES_NO_OPTION_LIST = [
  {
    label: i18n.t('USER.USER.SELECT.YES'),
    value: 'true',
  },
  {
    label: i18n.t('USER.USER.SELECT.NO'),
    value: 'false',
  },
];

export const METHOD_OPTION_LIST = [
  {
    label: i18n.t('USER.USER.SELECT.METHOD.COGNITIVE_BEHAVIORAL_THERAPY'),
    value: 1,
  },
  {
    label: i18n.t('USER.USER.SELECT.METHOD.GESTALTTHERAPY'),
    value: 2,
  },
  {
    label: i18n.t('USER.USER.SELECT.METHOD.PSYCHOANALYSIS'),
    value: 3,
  },
];

export const REQUEST_OPTION_LIST = [
  {
    label: i18n.t('USER.USER.SELECT.REQUEST.ANXIETY'),
    value: 1,
  },
  {
    label: i18n.t('USER.USER.SELECT.REQUEST.STRESS'),
    value: 2,
  },
  {
    label: i18n.t('USER.USER.SELECT.REQUEST.FAMILY_CONFLICT'),
    value: 3,
  },
];

export const API = {
  UPDATE: {
    TYPE: 'PATCH',
    URL: 'user/data-update',
  },
  GET: {
    TYPE: 'GET',
    URL: 'user/data/profile',
  },
  POST: {
    TYPE: 'POST',
    URL: 'user/photo',
  },
  DELETE: {
    TYPE: 'DELETE',
    URL: (id: string | null) => `file/${id}`,
  },
};

export interface ACTION_ERROR_INTER {
  error: string;
  message: string;
  statusCode: string;
}
