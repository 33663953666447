import { OPTION_INTER } from '../../data/user/constant';

export const convert = (values: any) => {
  return {
    ...values,
    workAnimalType: values?.workAnimalType.map((e: OPTION_INTER) => e.value),
    specialistWith: values?.specialistWith.map((e: OPTION_INTER) => e.value),
    transportation: values?.transportation.map((e: OPTION_INTER) => e.value),
    placement: values?.placement.map((e: OPTION_INTER) => e.value),
    ableCompatibilityAnimal:
      values?.ableCompatibilityAnimal === 'true' ? true : false,

    // requestList: values.requestList.map((e: OPTION_INTER) => e.value),
    // methodList: values.methodList.map((e: OPTION_INTER) => e.value),
    // lgbtFrendly: values.lgbtFrendly === 'true' ? true : false,
  };
};
