import React from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import {
  ACTION_ERROR_INTER,
  DATA_INTER,
  DATA_RAW_INTER,
  MODULE_NAME,
} from './constant';
import { Component } from './component';

import { getAction } from './action';
import { convert } from './convert';

export const Container: React.FC = () => {
  const { id } = useParams<{ id: string }>();

  const preFetch = useQuery([MODULE_NAME, id], () => getAction(id));

  const getData = (): DATA_INTER | undefined => {
    const data = preFetch.data as unknown as DATA_RAW_INTER;

    if (data) {
      return convert(data);
    }
  };

  const getErrorMessage = () => {
    const error = preFetch.error as ACTION_ERROR_INTER;
    return error?.message;
  };

  return (
    <Component
      isError={preFetch.isError}
      errorMessage={getErrorMessage()}
      isLoading={preFetch.isLoading}
      isSuccess={preFetch.isSuccess}
      data={getData()}
    />
  );
};
