import React, { useState } from 'react';
import styled from 'styled-components';

import { DATA_INTER } from '../constant';
import { Spacing } from '../../../theme';
import { COLOR_ENUM } from '../../../theme/color';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
} from '../../../theme/size';
import { TextElem } from '../../../common/text';

import { ReadMoreElem } from '../../../common/read-more';

export const Profile: React.FC<{ data?: DATA_INTER }> = ({ data }) => {
  return (
    <ProfileContainer>
      <MainContainer>
        <NameContainer>
          <ImgContainer>
            <ImgStyled src={data?.photo?.url} alt="photo" />
          </ImgContainer>
          <NameInfo>
            <TextElem
              type="medium"
              color="textPrimary"
              size="main"
              tid={data?.name}
            />
            <TextElem
              type="regular"
              color="textSecondary"
              size="semiSmall"
              tid="USER.PSYCHO.PROFILE.NAME"
              tvalue={{
                age: data?.age && `${data?.age}`,
                gender: data?.gender && `• ${data?.gender}`,
                timezone: data?.timezone && `• ${data?.timezone}`,
                lgbtFrendly: data?.lgbtFrendly ? '• LGBT+' : '',
              }}
            />
            <TextElem />
          </NameInfo>
        </NameContainer>
        <VideoContainer>
          <TextElem
            type="medium"
            color="textPrimary"
            size="main"
            tid="USER.PSYCHO.PROFILE.VIDEO"
          />
          <VideoPreviewContainer>
            {data?.video?.url && (
              <VideoStyled controls style={{ width: '100%', height: 'auto' }}>
                <source src={data?.video?.url} type="video/mp4" />
                Your browser does not support the video tag.
              </VideoStyled>
            )}
            {!data?.video?.url && (
              <TextElem
                type="medium"
                color="textPrimary"
                size="main"
                tid="USER.PSYCHO.PROFILE.NOT_SET"
              />
            )}
          </VideoPreviewContainer>
        </VideoContainer>
      </MainContainer>
      <InfoWrapper>
        <InfoContainer>
          <TextElem
            type="medium"
            color="textPrimary"
            size="main"
            tid="USER.PSYCHO.PROFILE.METHOD"
          />
          <ul>
            {data?.methodList && data?.methodList.length > 0 ? (
              data.methodList.map((e, index) => (
                <CustomListItem key={`method-${index}`}>
                  <TextElem
                    type="regular"
                    color="textSecondary"
                    size="input"
                    tid={e}
                  />
                </CustomListItem>
              ))
            ) : (
              <TextElem
                type="regular"
                color="textSecondary"
                size="input"
                tid="USER.PSYCHO.PROFILE.NOT_SET"
              />
            )}
          </ul>
          <ReadMoreElem textLimit={125} text={data?.methodCustom || ''} />
        </InfoContainer>
        <InfoContainer>
          <TextElem
            type="medium"
            color="textPrimary"
            size="main"
            tid="USER.PSYCHO.PROFILE.REQUEST"
          />
          <ul>
            {data?.requestList && data?.requestList.length > 0 ? (
              data.requestList.map((e, index) => (
                <CustomListItem key={`method-${index}`}>
                  <TextElem
                    type="regular"
                    color="textSecondary"
                    size="input"
                    tid={e}
                  />
                </CustomListItem>
              ))
            ) : (
              <TextElem
                type="regular"
                color="textSecondary"
                size="input"
                tid="USER.PSYCHO.PROFILE.NOT_SET"
              />
            )}
          </ul>
          <ReadMoreElem textLimit={125} text={data?.requestCustom || ''} />
        </InfoContainer>
      </InfoWrapper>
    </ProfileContainer>
  );
};

const CustomListItem = styled.li`
  position: relative;
  padding-left: ${Spacing(3)};

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 4px;
    height: 4px;
    background-color: ${({ theme }) => theme[COLOR_ENUM.TEXT_SECONDARY]};
    border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.CIRCLE]}px;
  }
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: ${Spacing(3)};
  height: 100%;
`;

const VideoContainer = styled.div`
  min-height: ${Spacing(85)};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[
    SIZE_BORDER_RADIUS_ENUM.PROFILE_PHOTO
  ]}px;

  padding: 22px ${Spacing(6)};
  background-color: ${({ theme }) => theme[COLOR_ENUM.WHITE]};
`;

const VideoStyled = styled.video`
  border-radius: ${SIZE_BORDER_RADIUS_DATA[
    SIZE_BORDER_RADIUS_ENUM.DROPDOWN
  ]}px !important;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  cursor: pointer;
`;

const VideoPreviewContainer = styled.div`
  height: 100%;
  width: 100%;
  min-height: 137px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const NameInfo = styled.div`
  display: flex;
  gap: ${Spacing(3)};
  flex-direction: column;
  height: 100%;
  justify-content: end;
`;

const ImgContainer = styled.div`
  min-width: 72px;
  min-height: 72px;
`;

const ImgStyled = styled.img`
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.CIRCLE]}px;
  width: 72px;
  height: 72px;
  object-fit: cover;
`;

const InfoContainer = styled.div`
  height: 100%;
  width: 100%;
  border-radius: ${SIZE_BORDER_RADIUS_DATA[
    SIZE_BORDER_RADIUS_ENUM.PROFILE_PHOTO
  ]}px;
  flex-direction: column;
  display: flex;
  gap: ${Spacing(3)};
  padding: 22px ${Spacing(6)};
  background-color: ${({ theme }) => theme[COLOR_ENUM.WHITE]};
`;

const NameContainer = styled.div`
  max-width: 527px;
  width: 100%;
  border-radius: ${SIZE_BORDER_RADIUS_DATA[
    SIZE_BORDER_RADIUS_ENUM.PROFILE_PHOTO
  ]}px;
  display: flex;
  gap: ${Spacing(4)};
  padding: ${Spacing(4)};
  background-color: ${({ theme }) => theme[COLOR_ENUM.WHITE]};
  align-items: center;
`;

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Spacing(4)};
`;

const ProfileContainer = styled.div`
  display: grid;
  gap: ${Spacing(4)};
  grid-template-columns: 1fr 1fr;
`;
