import React from 'react';
import styled from 'styled-components';
import { TextElem } from '../../common/text';
import { GridElem } from '../../common/grid';
import { COLOR_DATA, COLOR_ENUM } from '../../theme/color';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
  SIZE_FONT_WEIGHT_DATA,
  SIZE_FONT_WEIGHT_ENUM,
} from '../../theme/size';
import { useHistory } from 'react-router';
import { FormElem } from '../../common/form';
import { Spacing } from '../../theme';
import { FormikValues } from 'formik';
import { LoaderElem } from '../../common/loader';
import {
  citiesOptions,
  dateOptions,
  FORM_VALUE_ENUM,
  FORM_VALUE_TYPE,
  petsOptions,
  regionsOptions,
  servicesOptions,
  timeOptions,
} from './constant';
import { SelectElem } from '../../common/select';
import { ButtonElem } from '../../common/button';

export const Component: React.FC<{
  formik: FormikValues;
  isFieldError: Function;
  getFieldError: Function;
  isSubmitDisabled: any;
  getFieldValue: Function;
  isLoading?: boolean;
  isError?: boolean;
  errorMessage?: string;
  setFieldValue: Function;
  setValue: Function;
}> = ({
  formik,
  isFieldError,
  getFieldError,
  isSubmitDisabled,
  getFieldValue,
  isLoading,
  isError,
  errorMessage,
  setFieldValue,
  setValue,
}) => {
  const history = useHistory();

  const handleRedirect = (id: number) => {
    history.push(`/psycho-list?categoryList=${id}`);
  };

  const handleSelectChange =
    (name: FORM_VALUE_TYPE) => (selectedOption: any) => {
      formik.setFieldValue(name, selectedOption.value);
    };

  return (
    <FormWrapper>
      <Form onSubmit={formik.handleSubmit}>
        {isLoading && <LoaderElem />}
        <FormContainer>
          <TitleContainer>
            <TextStyled
              size="logo"
              color="textPrimary"
              type="semi-bold"
              tid="LANDING.HOME_CATALOG.CATALOG_TEXT.TITLE"
              fontFamily="Raleway"
            />

            <TextStyledSubttile
              oneLine
              size="default"
              color="textSecondary"
              type="medium"
              tid="LANDING.HOME_CATALOG.CATALOG_TEXT.SUBTITLE"
              fontFamily="Raleway"
            />
          </TitleContainer>

          <GridContainer spacing={3}>
            <Select
              name={FORM_VALUE_ENUM.NAME_PET}
              onChange={handleSelectChange(FORM_VALUE_ENUM.NAME_PET)}
              onBlur={formik.handleBlur}
              title="LANDING.HOME_CATALOG.SELECT_TEXT.NAME"
              value={formik.values[FORM_VALUE_ENUM.NAME_PET]}
              errorMessage={getFieldError(FORM_VALUE_ENUM.NAME_PET)}
              error={isFieldError(FORM_VALUE_ENUM.NAME_PET)}
              options={petsOptions}
            />

            <Select
              name={FORM_VALUE_ENUM.SERVICE}
              onChange={handleSelectChange(FORM_VALUE_ENUM.SERVICE)}
              onBlur={formik.handleBlur}
              title="LANDING.HOME_CATALOG.SELECT_TEXT.SERVICE"
              value={formik.values[FORM_VALUE_ENUM.SERVICE]}
              errorMessage={getFieldError(FORM_VALUE_ENUM.SERVICE)}
              error={isFieldError(FORM_VALUE_ENUM.SERVICE)}
              options={servicesOptions}
            />

            <Select
              name={FORM_VALUE_ENUM.CITY}
              onChange={handleSelectChange(FORM_VALUE_ENUM.CITY)}
              onBlur={formik.handleBlur}
              title="LANDING.HOME_CATALOG.SELECT_TEXT.CITY"
              value={formik.values[FORM_VALUE_ENUM.CITY]}
              errorMessage={getFieldError(FORM_VALUE_ENUM.CITY)}
              error={isFieldError(FORM_VALUE_ENUM.CITY)}
              options={citiesOptions}
            />

            <Select
              name={FORM_VALUE_ENUM.REGION}
              onChange={handleSelectChange(FORM_VALUE_ENUM.REGION)}
              onBlur={formik.handleBlur}
              title="LANDING.HOME_CATALOG.SELECT_TEXT.REGION"
              value={formik.values[FORM_VALUE_ENUM.REGION]}
              errorMessage={getFieldError(FORM_VALUE_ENUM.REGION)}
              error={isFieldError(FORM_VALUE_ENUM.REGION)}
              options={regionsOptions}
            />

            <Select
              name={FORM_VALUE_ENUM.TIME}
              onChange={handleSelectChange(FORM_VALUE_ENUM.TIME)}
              onBlur={formik.handleBlur}
              title="LANDING.HOME_CATALOG.SELECT_TEXT.TIME"
              value={formik.values[FORM_VALUE_ENUM.TIME]}
              errorMessage={getFieldError(FORM_VALUE_ENUM.TIME)}
              error={isFieldError(FORM_VALUE_ENUM.TIME)}
              options={timeOptions}
            />

            <Select
              name={FORM_VALUE_ENUM.DATE}
              onChange={handleSelectChange(FORM_VALUE_ENUM.DATE)}
              onBlur={formik.handleBlur}
              title="LANDING.HOME_CATALOG.SELECT_TEXT.DATE"
              value={formik.values[FORM_VALUE_ENUM.DATE]}
              errorMessage={getFieldError(FORM_VALUE_ENUM.DATE)}
              error={isFieldError(FORM_VALUE_ENUM.DATE)}
              options={dateOptions}
            />
          </GridContainer>

          <ButtonContainer>
            <ButtonStyled
              onClick={handleRedirect}
              disabled={isSubmitDisabled}
              type="submit"
              fill="solid"
              tid="LANDING.HOME_CATALOG.BUTTON_TEXT.TEXT"
              sizeText="main"
              textType="bold"
            />
          </ButtonContainer>
        </FormContainer>
      </Form>
    </FormWrapper>
  );
};

const FormWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: ${Spacing(20)};
  width: 100%;
  min-height: 100vh;
`;

const Form = styled(FormElem)`
  background-color: ${COLOR_DATA[COLOR_ENUM.BACKGROUND_PRIMARY]};
  background-size: cover;
  padding: 0px ${Spacing(49)} ${Spacing(49)};
  margin: 0;
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.MENU]}px;
  width: 100%;
  max-width: ${Spacing(350)};
  display: grid;
  justify-content: center;
  align-items: center;
  background: url('./assets/img/landing/background-form.jpeg') no-repeat center
    center;

  @media (max-width: 730px) {
    padding: ${Spacing(20)};
  }

  @media (max-width: 430px) {
    padding: ${Spacing(5)};
    background: url('./assets/img/landing/background-form.jpeg') center center;
  }
`;

const Select = styled(SelectElem)`
  width: 236px;

  @media (max-width: 430px) {
    width: 98%;
  }
`;

const ButtonContainer = styled.div``;

const ButtonStyled = styled(ButtonElem)`
  font-size: ${Spacing(4)};
  font-weight: ${SIZE_FONT_WEIGHT_DATA[SIZE_FONT_WEIGHT_ENUM.SEMI_BOLD]};
  line-height: ${Spacing(4)};
  letter-spacing: -0.01em;
  text-align: center;
`;

const FormContainer = styled.div`
  display: grid;
  background-color: ${COLOR_DATA[COLOR_ENUM.WHITE]};
  padding: ${Spacing(8)};
  width: 100%;
  max-width: 700px;
  border-radius: ${Spacing(5)};
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  gap: ${Spacing(5)};
  align-self: center;
  margin-top: 165px;

  @media (max-width: 430px) {
    margin-top: 0px;
  }
`;

const TitleContainer = styled.div`
  display: grid;
  justify-content: center;
  align-items: center;
  justify-items: center;
  padding-bottom: ${Spacing(8)};
`;

const TextStyled = styled(TextElem)`
  padding-bottom: ${Spacing(3)};
  font-weight: ${SIZE_FONT_WEIGHT_DATA[SIZE_FONT_WEIGHT_ENUM.BOLD]};

  @media (max-width: 730px) {
    font-size: ${Spacing(6)};
  }
`;

const TextStyledSubttile = styled(TextElem)`
  padding-bottom: ${Spacing(3)};

  @media (max-width: 730px) {
    font-size: ${Spacing(3.5)};
  }
`;

const GridContainer = styled(GridElem)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: ${Spacing(8)};
  column-gap: ${Spacing(4)};

  @media (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;
