import { API, FORM_VALUE_ENUM, FORM_VALUE_INTER } from './constant';
import { HttpRequest } from '../../lib/http';

export const getAction = (id?: string) => {
  return HttpRequest({
    method: API.GET.TYPE,
    url: API.GET.URL(id),
  });
};

export const action = (payload: FORM_VALUE_INTER) => {
  return HttpRequest({
    method: API.POST.TYPE,
    url: API.POST.URL,
    data: payload,
  });
};

export const actionTimezone = () => {
  return HttpRequest({
    method: API.GET_TIMEZONE.TYPE,
    url: API.GET_TIMEZONE.URL,
  });
};

export const actionTime = ({
  selectedDate,
  psychologistId,
}: {
  selectedDate: string;
  psychologistId: string;
}) => {
  return HttpRequest({
    method: API.GET_TIME.TYPE,
    url: API.GET_TIME.URL({ selectedDate, psychologistId }),
  });
};
