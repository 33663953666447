export enum PAYMENT_VALUTE {
  USD = 'USD',
  EUR = 'EUR',
  UAH = 'UAH',
  RUB = 'RUB',
}

export const PAYMENT_VALUTE_SYMBOL = {
  [PAYMENT_VALUTE.USD]: '$',
  [PAYMENT_VALUTE.EUR]: '€',
  [PAYMENT_VALUTE.UAH]: '$',
  [PAYMENT_VALUTE.RUB]: '$',
};

export enum PAYMENT_SYSTEM_TYPE {
  STRIPE = 'STRIPE',
  MANUAL = 'MANUAL',
}

export interface PAYMENT_ITEM_DATA_RAW_INTER {
  id: string;
  amount: number;
  valute: PAYMENT_VALUTE;
  stripePaid: boolean;
  system: PAYMENT_SYSTEM_TYPE;
  stripeChecked: boolean;
  manualPaid: boolean;
  confirm: boolean;
  updateDate: string;
  createDate: string;
}

export interface PAYMENT_ITEM_LIST_DATA_RAW_INTER {
  list: PAYMENT_ITEM_DATA_RAW_INTER[];
}

export interface PAYMENT_ITEM_DATA_INTER
  extends Omit<PAYMENT_ITEM_DATA_RAW_INTER, 'valute'> {
  status?: string;
  valute: string;
}

export interface PAYMENT_ITEM_LIST_DATA_INTER {
  list: PAYMENT_ITEM_DATA_INTER[];
  isEmpty: boolean;
}
