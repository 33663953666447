import { HttpRequest } from '../../lib/http';
import { API, filterOption } from './constant';

export const action = (filterOption?: filterOption) => {
  let params = '?';

  if (filterOption?.psychoCategory && filterOption?.psychoCategory !== 'All') {
    params += `categoryList=${filterOption.psychoCategory}&`;
  }

  if (filterOption?.methodList) {
    params += `methodList=${filterOption.methodList}&`;
  }

  if (filterOption?.requestList) {
    params += `requestList=${filterOption.requestList}&`;
  }

  params.slice(0, -1);

  return HttpRequest({
    method: API.TYPE,
    url: API.URL(params),
  });
};
