import { TIMEZONE_ENUM, TIMEZONE_VIEW } from '../../data/user/timezone';
import { getYearLabel } from '../../lib/util/yearConvert';
import {
  CATEGORY_VIEW,
  DATA_INTER,
  DATA_RAW_INTER,
  GENDER_VIEW,
  METHOD_VIEW,
  REQUEST_VIEW,
} from './constant';

export const convert = (data: DATA_RAW_INTER): DATA_INTER => ({
  ...data,
  methodList: data?.methodList?.map((e) => METHOD_VIEW[e]),
  requestList: data?.methodList?.map((e) => REQUEST_VIEW[e]),
  gender: GENDER_VIEW[data.gender],
  category: data?.category?.map((e) => ({
    ...e,
    category: CATEGORY_VIEW[e.category],
  })),
  timezone: TIMEZONE_VIEW[data.timezone as TIMEZONE_ENUM],
  age: getYearLabel(data.age),
});
