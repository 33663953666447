import React, { useState } from 'react';
import styled from 'styled-components';

import { TabSecondaryElem } from '../../common/tab-secondary';
import { HeaderSecondary } from '../../common/header-secondary';
import { ProfilePsychoUpdateContainer } from '../profile-psycho-update-data';
import { ProfilePsychoUpdateFileContainer } from '../profile-psycho-update-file';
import { Spacing } from '../../theme';
import { ProfilePsychoUpdateSpecializationContainer } from '../profile-psycho-update-specialization';
import { ProfilePsychoUpdateRegardContainer } from '../profile-psycho-update-regard';
import { ProfilePsychoListCategoryContainer } from '../profile-psycho-category-list';

export const Component: React.FC<{}> = ({}) => {
  const [active, setActive] = useState(1);

  const tabList = [
    { tid: 'USER.PSYCHOLOGIST.TAB.CONTACT_INFO', value: 1 },
    { tid: 'USER.PSYCHOLOGIST.TAB.SPECIALIZATION', value: 2 },
    { tid: 'USER.PSYCHOLOGIST.TAB.EXPERIENCE', value: 3 },
    { tid: 'USER.PSYCHOLOGIST.TAB.SERVICE', value: 4 },
  ];

  return (
    <Wrapper>
      <HeaderSecondary
        titleTid="USER.PSYCHOLOGIST.TITLE.NAME"
        prefixTid="USER.PSYCHOLOGIST.TITLE.PREFIX"
        sufixTid="USER.PSYCHOLOGIST.TITLE.SUFIX"
      />
      <TabSecondaryElem
        tabList={tabList}
        active={active}
        setActive={setActive}
      />
      {active === 1 && (
        <ContentContainer>
          {/* <ProfilePsychoUpdateFileContainer /> */}
          <ProfilePsychoUpdateContainer />
        </ContentContainer>
      )}
      {active === 2 && <ProfilePsychoUpdateSpecializationContainer />}
      {active === 3 && <ProfilePsychoUpdateRegardContainer />}

      {active === 4 && <ProfilePsychoListCategoryContainer />}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  max-width: 500px;
  display: flex;
  flex-direction: column;
  gap: ${Spacing(4)};
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Spacing(5)};
`;
