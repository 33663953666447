import React from 'react';
import styled from 'styled-components';

import { DATA_INTER } from './constant';
import { Spacing } from '../../theme';
import { SkeletonProfileElem } from '../../common/skeleton-profile';
import { FormikValues } from 'formik';
import { Category } from './frame/category';
import { Profile } from './frame/profile';
import { COLOR_ENUM } from '../../theme/color';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
} from '../../theme/size';
import { TextElem } from '../../common/text';
import { HeaderOrder } from './frame/header';
import { OrderAuthSignupContainer } from '../order-auth-signup';
import { AuthVerificationContainer } from '../auth-verification';
import { Order } from './frame/order';
import { AUTH_TYPE } from '../../data/auth/constant';
import { generateDateList } from '../../lib/util/generateDateList';
import { AlertActionElem } from '../../common/alert-action';

export const Component: React.FC<{
  data?: DATA_INTER;
  isLoading: boolean;
  isSuccess: boolean;
  formik: FormikValues;
  isFieldError: Function;
  getFieldError: Function;
  isSubmitDisabled: Function;
  getFieldValue: Function;
  isError?: boolean;
  errorMessage?: string;
  setFieldValue: Function;
  setValue: Function;
  isLogged: boolean | null;
  isConfirmEmail: boolean | null;
  timezone: number;
  timeOption: any[];
  handleSelectFocus: (value: boolean) => void;
  isFieldDisabled: Function;
  isTimeLoading: boolean;
}> = ({
  data,
  isSuccess,
  isLoading,
  setFieldValue,
  getFieldError,
  isFieldError,
  setValue,
  getFieldValue,
  formik,
  isSubmitDisabled,
  isLogged,
  isConfirmEmail,
  timezone,
  timeOption,
  handleSelectFocus,
  isFieldDisabled,
  isTimeLoading,
  isError,
  errorMessage,
}) => {
  const dateOption = generateDateList(timezone);

  return (
    <>
      {isLoading && <SkeletonProfileElem />}
      {isError && <AlertActionElem text={errorMessage} />}
      {isSuccess && (
        <>
          <HeaderOrder />
          <Container>
            <Profile user={data?.user} />
            <Category
              data={data}
              setFieldValue={setFieldValue}
              getFieldError={getFieldError}
              isFieldError={isFieldError}
              setValue={setValue}
              getFieldValue={getFieldValue}
            />
            <InfoContainer>
              <TextContainer>
                <TextElem
                  lineHeight="1.6em"
                  type="medium"
                  color="textPrimary"
                  size="main"
                  tid="ORDER.ITEM.ABOUT_SERVICE"
                />
                <TextElem
                  type="regular"
                  color="textSecondary"
                  size="input"
                  tid={data?.user.aboutMyService}
                />
              </TextContainer>
            </InfoContainer>
            {!isLogged && <OrderAuthSignupContainer />}
            {!isConfirmEmail && isLogged && (
              <AuthVerificationContainer
                type={AUTH_TYPE.EMAIL}
                withoutRedirect
              />
            )}
            <Order
              isTimeLoading={isTimeLoading}
              isFieldDisabled={isFieldDisabled}
              handleSelectFocus={handleSelectFocus}
              timeOption={timeOption}
              dateOption={dateOption}
              isSubmitDisabled={isSubmitDisabled}
              isFieldError={isFieldError}
              getFieldError={getFieldError}
              getFieldValue={getFieldValue}
              setValue={setValue}
              setFieldValue={setFieldValue}
              formik={formik}
            />
          </Container>
        </>
      )}
    </>
  );
};

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Spacing(2)};
`;

const InfoContainer = styled.div`
  width: 100%;
  border-radius: ${SIZE_BORDER_RADIUS_DATA[
    SIZE_BORDER_RADIUS_ENUM.PROFILE_PHOTO
  ]}px;
  flex-direction: column;
  display: flex;
  gap: ${Spacing(3)};
  padding: ${Spacing(6)};
  background-color: ${({ theme }) => theme[COLOR_ENUM.WHITE]};
`;

const Container = styled.div`
  display: grid;
  grid-gap: ${Spacing(4)};
  margin-top: 38px;
`;
