import React, { useState } from 'react';
import { TextElem } from '../text';
import styled from 'styled-components';
import { COLOR_ENUM } from '../../theme/color';
import { i18n } from '../../lib/lang';

export const Elem: React.FC<{
  textLimit?: number;
  text: string;
  onToggle?: Function;
}> = ({ textLimit = 150, text, onToggle }) => {
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = (e: any) => {
    e.stopPropagation();
    setIsReadMore(!isReadMore);
    onToggle && onToggle();
  };

  const localText = i18n.t(text);
  const isReadMoreText = localText.length > textLimit;

  return (
    <TextElem
      lineHeight="1.6em"
      as="p"
      type="regular"
      color="textSecondary"
      size="input"
    >
      {isReadMore && isReadMoreText
        ? localText.slice(0, textLimit) + '...'
        : localText}

      {isReadMoreText && (
        <SpanStyled
          onClick={toggleReadMore}
          style={{ cursor: 'pointer', marginLeft: '4px' }}
        >
          {isReadMore ? 'Читати більше' : 'Згорнути'}
        </SpanStyled>
      )}
    </TextElem>
  );
};

const SpanStyled = styled.span`
  color: ${({ theme }) => theme[COLOR_ENUM.TEXT_PRIMARY]};
`;
