import {
  GENDER_ENUM,
  METHOD_ENUM,
  REQUEST_ENUM,
} from '../../data/user/constant';
import { TIMEZONE_ENUM, TIMEZONE_VIEW } from '../../data/user/timezone';
import {
  CATEGORY_ENUM,
  CATEGORY_VIEW,
} from '../profile-psycho-create-category/constant';
import {
  METHOD_VIEW,
  REQUEST_VIEW,
  GENDER_VIEW,
} from '../profile-psycho/constant';
import { DATA_INTER, DATA_RAW_INTER } from './constant';

export const convert = (data: DATA_RAW_INTER): DATA_INTER => {
  const userTimezone = Number(data.psychologist.timezone);
  const utcDate = new Date(data.date);

  const convertedDate = new Date(
    utcDate.getTime() + userTimezone * 60 * 60 * 1000,
  );

  return {
    ...data,
    methodList: data?.psychologist.methodList?.map(
      (e) => METHOD_VIEW[e as unknown as METHOD_ENUM],
    ),
    requestList: data?.psychologist.methodList?.map(
      (e) => REQUEST_VIEW[e as unknown as REQUEST_ENUM],
    ),
    category: CATEGORY_VIEW[data.category as unknown as CATEGORY_ENUM],
    gender: GENDER_VIEW[data.psychologist.gender as unknown as GENDER_ENUM],
    timezone:
      TIMEZONE_VIEW[data.psychologist.timezone as unknown as TIMEZONE_ENUM],
    date: convertedDate.toISOString(),
  };
};
