import React from 'react';
import styled from 'styled-components';

import { TextElem } from '../../common/text';
import { LoaderElem } from '../../common/loader';
import { AlertActionElem } from '../../common/alert-action';
import { COLOR_ENUM } from '../../theme/color';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
} from '../../theme/size';
import { Spacing } from '../../theme';
import { ButtonElem } from '../../common/button';
import { SkeletonFormElem } from '../../common/skeleton-form';
import { FileItemUploadContainer } from '../file-item-upload';
import { FormikValues } from 'formik';
import { FORM_VALUE_ENUM } from './constant';
import { FieldTextAreaElem } from '../../common/field-text-area/index';
import { FieldTextElem } from '../../common/field-text/index';
import { FormElem } from '../../common/form/index';

export const Component: React.FC<{
  isCertificateLoading?: boolean;
  isCertificateError?: boolean;
  // diplomaIsLoading?: boolean;
  certificateIsLoading?: boolean;
  isCertificateUploading?: boolean;
  handleDelete: Function;
  dataCertificate: any;
  preFetchSuccessCeritificate: boolean;
  onRefetch: Function;
  visibleCertificate: number;
  deleteAction: Function;
  // visibleDiploma: number;
  handleAddCertificate: Function;
  // handleAddDiploma: Function;
  // isDisabledDiploma: boolean;
  isDisabledCertificate: boolean;
  isSuccessDelete: boolean;
  formik: FormikValues;
  isFieldError: Function;
  getFieldError: Function;
  isSubmitDisabled: Function;
  getFieldValue: Function;
  isLoading?: boolean;
  isSuccess?: boolean;
  isError?: boolean;
  errorMessage?: string;
  setFieldValue?: Function;
  setValue?: Function;
  onChangeSelect: (name: string, values: any) => void;
  isLoadingAction: boolean;
  isSuccessAction: boolean;
}> = ({
  isCertificateLoading,
  isCertificateUploading,
  handleDelete,
  dataCertificate,
  preFetchSuccessCeritificate,
  onRefetch,
  visibleCertificate,
  deleteAction,
  // visibleDiploma,
  handleAddCertificate,
  // handleAddDiploma,
  // isDisabledDiploma,
  isDisabledCertificate,
  isSuccessDelete,
  formik,
  isFieldError,
  getFieldError,
  isSubmitDisabled,
  getFieldValue,
  isLoading,
  isSuccess,
  isError,
  errorMessage,
  setValue = () => {},
  setFieldValue,
  isLoadingAction,
  isSuccessAction,
}) => {
  return (
    <>
      <FormElem gap={7} onSubmit={formik.handleSubmit}>
        {isLoadingAction && <LoaderElem />}
        {isLoading && <SkeletonFormElem />}
        {isSuccess && (
          <Container>
            {/* <FileButtonContainer>
            {data?.diploma?.length > 0 &&
              data.diploma.map((e: File | any, index: number) => (
                <FileItemUploadContainer
                  key={e?.id}
                  type="diploma"
                  isDot={index === 0 ? true : false}
                  title={index === 0 ? 'COMMON.FILE.DIPLOMA_CONTENT_TITLE' : ''}
                  defaultValue={e?.url}
                  onSuccess={() => {
                    onRefetch();
                  }}
                  onReset={() => {
                    handleDelete(e?.id);
                  }}
                />
              ))}

            {[...Array(visibleDiploma)].map((_, index) => (
              <>
                <FileItemUploadContainer
                  key={index}
                  isDot={data?.diploma?.length === 0 ? true : false}
                  title={
                    data?.diploma?.length === 0
                      ? 'COMMON.FILE.DIPLOMA_CONTENT_TITLE'
                      : ''
                  }
                  type="diploma"
                  onSuccess={onRefetch}
                />
              </>
            ))}

            <ButtonElem
              tid="COMMON.FILE.UPLOAD"
              type="file"
              onClick={handleAddDiploma}
              disabled={isDisabledDiploma}
            />
          </FileButtonContainer> */}
            <FieldTextElem
              type="number"
              name={FORM_VALUE_ENUM.YEAR_EXPERIENCE}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={getFieldValue(FORM_VALUE_ENUM.YEAR_EXPERIENCE)}
              title="USER.PSYCHOLOGIST.YEAR_EXPERIENCE"
              placeholder="USER.PSYCHOLOGIST.YEAR_EXPERIENCE_PLACEHOLDER"
              errorMessage={getFieldError(FORM_VALUE_ENUM.YEAR_EXPERIENCE)}
              error={isFieldError(FORM_VALUE_ENUM.YEAR_EXPERIENCE)}
            />
            <FieldTextElem
              type="number"
              name={FORM_VALUE_ENUM.HOURS_EXPERIENCE}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={getFieldValue(FORM_VALUE_ENUM.HOURS_EXPERIENCE)}
              title="USER.PSYCHOLOGIST.HOURS_EXPERIENCE"
              placeholder="USER.PSYCHOLOGIST.HOURS_EXPERIENCE_PLACEHOLDER"
              errorMessage={getFieldError(FORM_VALUE_ENUM.HOURS_EXPERIENCE)}
              error={isFieldError(FORM_VALUE_ENUM.HOURS_EXPERIENCE)}
            />

            {isCertificateUploading && <LoaderElem />}
            {preFetchSuccessCeritificate && !isCertificateLoading && (
              <FileButtonContainer>
                {dataCertificate?.certificate?.length > 0 &&
                  dataCertificate.certificate.map(
                    (e: File | any, index: number) => (
                      <FileItemUploadContainer
                        key={e?.id}
                        type="certificate"
                        title={
                          index === 0
                            ? 'COMMON.FILE.CERTIFICATE_CONTENT_TITLE'
                            : ''
                        }
                        defaultValue={e?.url}
                        onSuccess={() => {
                          onRefetch();
                        }}
                        onReset={() => {
                          handleDelete(e?.id);
                        }}
                      />
                    ),
                  )}

                {[...Array(visibleCertificate)].map((_, index) => (
                  <>
                    <FileItemUploadContainer
                      key={index}
                      type="certificate"
                      onSuccess={onRefetch}
                      title={
                        dataCertificate?.certificate?.length === 0
                          ? 'COMMON.FILE.CERTIFICATE_CONTENT_TITLE'
                          : ''
                      }
                    />
                  </>
                ))}

                <ButtonElem
                  tid="COMMON.FILE.UPLOAD"
                  type="file"
                  onClick={handleAddCertificate}
                  disabled={isDisabledCertificate}
                />
              </FileButtonContainer>
            )}

            <FieldTextAreaElem
              name={FORM_VALUE_ENUM.ABOUT_SPECIALIST}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={getFieldValue(FORM_VALUE_ENUM.ABOUT_SPECIALIST)}
              title="USER.PSYCHOLOGIST.ABOUT_SPECIALIST"
              placeholder="USER.PSYCHOLOGIST.ABOUT_SPECIALIST_PLACEHOLDER"
              errorMessage={getFieldError(FORM_VALUE_ENUM.ABOUT_SPECIALIST)}
              error={isFieldError(FORM_VALUE_ENUM.ABOUT_SPECIALIST)}
            />

            <FieldTextAreaElem
              name={FORM_VALUE_ENUM.PROFILE_DESCRIPTION}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={getFieldValue(FORM_VALUE_ENUM.PROFILE_DESCRIPTION)}
              title="USER.PSYCHOLOGIST.PROFILE_DESCRIPTION"
              placeholder="USER.PSYCHOLOGIST.PROFILE_DESCRIPTION"
              errorMessage={getFieldError(FORM_VALUE_ENUM.PROFILE_DESCRIPTION)}
              error={isFieldError(FORM_VALUE_ENUM.PROFILE_DESCRIPTION)}
            />
            {/* 
            <TextElem
              tid="USER.PSYCHOLOGIST.VIDEO_TITLE"
              type="medium"
              size="main"
              color="buttonText"
            /> */}
            <FileItemUploadContainer
              type="video"
              defaultValue={dataCertificate?.videoIntroduction?.url}
              onSuccess={onRefetch}
              onReset={() => {
                deleteAction();
                onRefetch();
              }}
              title="COMMON.FILE.VIDEO_TITLE"
            />

            <FieldTextAreaElem
              name={FORM_VALUE_ENUM.ABOUT_PERSON}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={getFieldValue(FORM_VALUE_ENUM.ABOUT_PERSON)}
              title="USER.PSYCHOLOGIST.ABOUT_PERSON"
              placeholder="USER.PSYCHOLOGIST.ABOUT_PERSON_PLACEHOLDER"
              errorMessage={getFieldError(FORM_VALUE_ENUM.ABOUT_PERSON)}
              error={isFieldError(FORM_VALUE_ENUM.ABOUT_PERSON)}
            />
            <FieldTextAreaElem
              name={FORM_VALUE_ENUM.MY_PATH}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={getFieldValue(FORM_VALUE_ENUM.MY_PATH)}
              title="USER.PSYCHOLOGIST.MY_PATH"
              placeholder="USER.PSYCHOLOGIST.MY_PATH_PLACEHOLDER"
              errorMessage={getFieldError(FORM_VALUE_ENUM.MY_PATH)}
              error={isFieldError(FORM_VALUE_ENUM.MY_PATH)}
            />
            <FieldTextAreaElem
              name={FORM_VALUE_ENUM.WORKING_METHODS}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={getFieldValue(FORM_VALUE_ENUM.WORKING_METHODS)}
              title="USER.PSYCHOLOGIST.WORKING_METHODS"
              placeholder="USER.PSYCHOLOGIST.WORKING_METHODS_PLACEHOLDER"
              errorMessage={getFieldError(FORM_VALUE_ENUM.WORKING_METHODS)}
              error={isFieldError(FORM_VALUE_ENUM.WORKING_METHODS)}
            />
            <FieldTextAreaElem
              name={FORM_VALUE_ENUM.MEDICATION_EXPERIENCE}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={getFieldValue(FORM_VALUE_ENUM.MEDICATION_EXPERIENCE)}
              title="USER.PSYCHOLOGIST.MEDICATION_EXPERIENCE"
              placeholder="USER.PSYCHOLOGIST.MEDICATION_EXPERIENCE_PLACEHOLDER"
              errorMessage={getFieldError(
                FORM_VALUE_ENUM.MEDICATION_EXPERIENCE,
              )}
              error={isFieldError(FORM_VALUE_ENUM.MEDICATION_EXPERIENCE)}
            />

            {/* <FieldTextElem
                  name={FORM_VALUE_ENUM.NATIONALITY}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  title="USER.PSYCHOLOGIST.NATIONALITY"
                  placeholder="USER.PSYCHOLOGIST.NATIONALITY_PLACEHOLDER"
                  value={getFieldValue(FORM_VALUE_ENUM.NATIONALITY)}
                  errorMessage={getFieldError(FORM_VALUE_ENUM.NATIONALITY)}
                  error={isFieldError(FORM_VALUE_ENUM.NATIONALITY)}
                /> */}

            <TitleContainer>
              <Dot />
              <TextElem
                tid="USER.PSYCHOLOGIST.REQUIRED"
                type="light"
                size="input"
                color="textSecondary"
              />
            </TitleContainer>
            <ButtonElem
              disabled={isSubmitDisabled()}
              type="submit"
              tid="CATEGORY.DATA.BUTTON"
            />

            {isSuccessDelete && (
              <AlertActionElem type="success" tid="USER.FILE.DELETE" />
            )}
            {isSuccessAction && (
              <AlertActionElem type="success" tid="CATEGORY.DATA.SUCCESS" />
            )}
            {isError && <AlertActionElem text={errorMessage} />}
          </Container>
        )}
      </FormElem>
    </>
  );
};

const FileButtonContainer = styled.div`
  display: grid;
  grid-gap: ${Spacing(3)};
`;

const TitleContainer = styled.div`
  display: flex;
  gap: 6px;
  align-items: center;
  margin: ${Spacing(2)} 0;
`;

const Dot = styled.div`
  height: 6px;
  width: 6px;
  background-color: ${({ theme }) => theme[COLOR_ENUM.DEFAULT]};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.CIRCLE]}px;
  position: relative;
  top: 1px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Spacing(4)};
  background-color: ${({ theme }) => theme[COLOR_ENUM.WHITE]};
  padding: ${Spacing(7)};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.CONTENT]}px;
  width: 100%;F
`;
