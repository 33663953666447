import React from 'react';
import styled from 'styled-components';

import { DATA_INTER } from '../constant';
import { Spacing } from '../../../theme';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
} from '../../../theme/size';
import { COLOR_ENUM } from '../../../theme/color';
import { TextElem } from '../../../common/text';
import uploadDocumentSvg from '../../../asset/svg/common/document-upload.svg';
import {
  VALUE_TRANSITION_DATA,
  VALUE_TRANSITION_ENUM,
  VALUE_OPACITY_DATA,
  VALUE_OPACITY_ENUM,
} from '../../../theme/value';

export const FileComponent: React.FC<{ data?: DATA_INTER }> = ({ data }) => {
  const openFile = (url: string) => {
    window.open(url, '_blank');
  };


  return (
    <Container>
      <InfoContainer>
        <TextElem
          type="medium"
          color="textPrimary"
          size="main"
          tid="USER.PSYCHO.PROFILE.CERTIFICATE"
        />
        <FileContainer>
          {data?.certificate && data?.certificate.length > 0 ? (
            data.certificate.map((e) => (
              <FilePreview onClick={() => openFile(e.url)}>
                <img src={uploadDocumentSvg} alt="certificate" />
              </FilePreview>
            ))
          ) : (
            <TextElem
              type="medium"
              color="textPrimary"
              size="main"
              tid="USER.PSYCHO.PROFILE.NOT_SET"
            />
          )}
        </FileContainer>
      </InfoContainer>
      <InfoContainer>
        <TextElem
          type="medium"
          color="textPrimary"
          size="main"
          tid="USER.PSYCHO.PROFILE.DIPLOMA"
        />
        <FileContainer>
          <FileContainer>
            {data?.diploma && data?.diploma.length > 0 ? (
              data.diploma.map((e) => (
                <FilePreview onClick={() => openFile(e.url)}>
                  <img src={uploadDocumentSvg} alt="diploma" />
                </FilePreview>
              ))
            ) : (
              <TextElem
                type="medium"
                color="textPrimary"
                size="main"
                tid="USER.PSYCHO.PROFILE.NOT_SET"
              />
            )}
          </FileContainer>
        </FileContainer>
      </InfoContainer>
    </Container>
  );
};

const FileContainer = styled.div`
  display: flex;
  gap: ${Spacing(2)};
  max-width: ${Spacing(105)};
  flex-wrap: wrap;
`;

const FilePreview = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.CARD]}px;
  width: 98px;
  height: 70px;
  background-color: ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_PRIMARY]};

  transition: ${VALUE_TRANSITION_DATA[VALUE_TRANSITION_ENUM.HOVER]};
  cursor: pointer;

  :hover {
    opacity: ${VALUE_OPACITY_DATA[VALUE_OPACITY_ENUM.HOVER]};
  }
`;

const Container = styled.div`
  display: flex;
  gap: ${Spacing(3)};
`;

const InfoContainer = styled.div`
  width: 100%;
  border-radius: ${SIZE_BORDER_RADIUS_DATA[
    SIZE_BORDER_RADIUS_ENUM.PROFILE_PHOTO
  ]}px;
  flex-direction: column;
  display: flex;
  gap: ${Spacing(4)};
  padding: ${Spacing(7)};
  background-color: ${({ theme }) => theme[COLOR_ENUM.WHITE]};
`;
