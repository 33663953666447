import React, { useState } from 'react';
import styled from 'styled-components';
import { PAYMENT_ITEM_DATA_INTER } from '../constant';
import { Spacing } from '../../../theme';

import { CardElem } from '../../../common/card';

import { TextElem } from '../../../common/text';
import { EllipsisContainerElem } from '../../../common/ellipsis-container';
import { PaymentItemContainer } from '../../../epic/payment-item';
import { COLOR_ENUM } from '../../../theme/color';

const Elem: React.FC<PAYMENT_ITEM_DATA_INTER> = ({
  id,
  createDate,
  amount,
  valute,
  ...props
}) => {
  const [modalVisible, setModalVisible] = useState(false);
  const modalClose = (e: any) => {
    if (e) {
      e.stopPropagation();
    }
    setModalVisible(false);
  };
  const modalOpen = () => {
    setModalVisible(true);
  };
  return (
    <Card handleClick={modalOpen}>
      {modalVisible && (
        <PaymentItemContainer
          paymentId={id}
          visible={modalVisible}
          onClose={modalClose}
        />
      )}
      <TextElem size="semiSmall" oneLine color="textPrimary" tid={id} />
      <TextElem size="semiSmall" tid={createDate} oneLine color="textPrimary" />
      <TextElem
        type="medium"
        size="semiSmall"
        oneLine
        tvalue={{ valute, amount }}
        tid="PAYMENT.ITEM.PRICE"
      />
    </Card>
  );
};

const Card = styled(CardElem)<{}>`
  display: grid;
  grid-column: span 5;
  grid-template-columns: subgrid;
  gap: 0;
  justify-content: space-between;
  position: relative;
  align-items: center;
  gap: ${Spacing(12)};
  padding: ${Spacing(4)} ${Spacing(5)};
  background: ${({ theme }) => theme[COLOR_ENUM.WHITE]};
  border-radius: 14px;
  height: 56px;
`;

export { Elem as PaymentItemElem };
