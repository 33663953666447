import React from 'react';
import styled from 'styled-components';

export const Elem: React.FC<{}> = () => {
  return <LogoSvg src="./assets/svg/arrow-down.svg" alt="Arrow-bottom" />;
};

const LogoSvg = styled.img`
  width: 16px;
  height: 16px;
`;
