import React from 'react';

import styled, { css } from 'styled-components';
import psychoIcon from '../../asset/svg/menu-app/psycho-list.svg';

import { FORM_VALUE_ENUM } from './constant';
import { SelectElem } from '../../common/select';
import {
  METHOD_OPTION_LIST,
  REQUEST_OPTION_LIST,
} from '../profile-user-update-data/constant';
import { Spacing } from '../../theme';
import { TextElem } from '../../common/text';
import { SIZE_LAYOUT_DATA, SIZE_LAYOUT_ENUM } from '../../theme/size';
import { CATEGORY_OPTION_LIST } from '../profile-psycho-create-category/constant';

export const Component: React.FC<{
  isFieldError: Function;
  getFieldError: Function;
  onChangeSelect: (name: string, values: any) => void;
  getFieldValue: Function;
  isLoading?: boolean;
  setFieldValue: Function;
  setValue: Function;
}> = ({
  isFieldError,
  getFieldError,
  onChangeSelect,
  getFieldValue,
  isLoading,
  setFieldValue,
  setValue,
}) => {
  const multiValueContainer = ({ selectProps, data }: any) => {
    const label = data.label;
    const allSelected = selectProps.value;
    const index = allSelected.findIndex(
      (selected: any) => selected.label === label,
    );
    const isLastSelected = index === allSelected?.length - 1;
    const labelSuffix = isLastSelected ? '' : ', ';
    const val = `${label}${labelSuffix}`;
    return val;
  };

  return (
    <Container>
      <HeaderContainer>
        <img src={psychoIcon} alt="header logo" />
        <TextElem
          color="textPrimary"
          type="medium"
          size="medium"
          tid="Психологи"
        />
      </HeaderContainer>
      <SelectContainer>
        <SelectElem
          isClearable
          maxWidth="220px"
          minWidth="220px"
          backgroundColor="white"
          placeholder="PSYCHO_LIST.FILTER.CATEGORY"
          name={FORM_VALUE_ENUM.PSYCHO_CATEGORY}
          onChange={setFieldValue}
          options={CATEGORY_OPTION_LIST}
          errorMessage={getFieldError(FORM_VALUE_ENUM.PSYCHO_CATEGORY)}
          error={isFieldError(FORM_VALUE_ENUM.PSYCHO_CATEGORY)}
          value={setValue(
            CATEGORY_OPTION_LIST,
            FORM_VALUE_ENUM.PSYCHO_CATEGORY,
          )}
        />

        <SelectElem
          backgroundColor="white"
          maxWidth="220px"
          minWidth="220px"
          placeholder="PSYCHO_LIST.FILTER.METHOD"
          isMulti
          name={FORM_VALUE_ENUM.METHOD_LIST}
          options={METHOD_OPTION_LIST}
          errorMessage={getFieldError(FORM_VALUE_ENUM.METHOD_LIST)}
          error={isFieldError(FORM_VALUE_ENUM.METHOD_LIST)}
          value={getFieldValue(FORM_VALUE_ENUM.METHOD_LIST)}
          onChange={onChangeSelect}
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          customComponents={{ MultiValueContainer: multiValueContainer }}
          isDisabled={isLoading}
        />

        <SelectElem
          backgroundColor="white"
          maxWidth="220px"
          minWidth="220px"
          placeholder="PSYCHO_LIST.FILTER.REQUEST"
          isMulti
          name={FORM_VALUE_ENUM.REQUEST_LIST}
          options={REQUEST_OPTION_LIST}
          errorMessage={getFieldError(FORM_VALUE_ENUM.REQUEST_LIST)}
          error={isFieldError(FORM_VALUE_ENUM.REQUEST_LIST)}
          value={getFieldValue(FORM_VALUE_ENUM.REQUEST_LIST)}
          onChange={onChangeSelect}
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          customComponents={{ MultiValueContainer: multiValueContainer }}
          isDisabled={isLoading}
        />
      </SelectContainer>
    </Container>
  );
};

const HeaderContainer = styled.div`
  display: flex;
  gap: ${Spacing(2)};
  align-items: center;
`;

const SelectContainer = styled.div`
  display: flex;
  gap: ${Spacing(3)};
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  margin-top: ${Spacing(10)};
  align-self: end;
  padding: 0px 24px;
  align-items: center;
  max-width: calc(
    ${SIZE_LAYOUT_DATA[SIZE_LAYOUT_ENUM.DEFAULT]}px + ${Spacing(10 * 2)}
  );
`;
