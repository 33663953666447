import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';

import { useSelector } from '../../lib/store';
import { STORE_INTER } from '../../data/auth/constant';

import {
  ACTION_ERROR_INTER,
  DATA_INTER,
  DATA_RAW_INTER,
  FORM_VALUE_ENUM,
  FORM_VALUE_INTER,
  FORM_VALUE_TYPE,
  MODULE_NAME,
} from './constant';
import { Component } from './component';

import {
  actionTime,
  actionTimezone,
  action as fetch,
  getAction,
} from './action';
import { convert } from './convert';
import { validation } from '../../lib/validation';
import { FormikValues, useFormik } from 'formik';
import { required } from '../../lib/validation/service';
import { SELECT_OPTION_ITEM_DATA } from '../../common/select';
import { AUTH_MODULE_NAME } from '../../data/auth';
import { CONSULTATION_PAGE_PATH_DYNAMIC } from '../../page/consultation';

export const Container: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [activeId, setActiveId] = useState(id);
  const [selectedTime, setSelectedTime] = useState('');
  const [timeSelectFocus, setTimeSelectFocus] = useState(false);
  const [timeOption, setTimeOption] = useState<
    {
      label: string;
      value: string;
    }[]
  >([]);
  const history = useHistory();

  const handleSelectFocus = (value: boolean) => {
    setTimeSelectFocus(value);
  };

  const state: STORE_INTER = useSelector((s) => s[AUTH_MODULE_NAME]);

  const isLogged = () => state.logged;
  const isConfirmEmail = () => state?.isConfirmEmail;

  const preFetch = useQuery([MODULE_NAME, id], () => getAction(activeId || id));

  const fetchTimezone = useQuery('timezone', actionTimezone);

  const timezone = (fetchTimezone.data as unknown as number) || 0;

  const getData = (): DATA_INTER | undefined => {
    const data = preFetch.data as unknown as DATA_RAW_INTER;
    if (data) {
      return convert(data);
    }
  };

  const data = getData();

  const onSuccess = (data: any) => {
    history.push(CONSULTATION_PAGE_PATH_DYNAMIC(data.id));
  };

  const action = useMutation(fetch, { onSuccess });

  const config = {
    [FORM_VALUE_ENUM.CATEGORY]: [required],
    [FORM_VALUE_ENUM.COMMENT]: [],
    [FORM_VALUE_ENUM.DATE]: [required],
    [FORM_VALUE_ENUM.TIME]: [required],
  };

  const validate = (values: FormikValues) => validation(values, config);


  const initialValues = {
    [FORM_VALUE_ENUM.CATEGORY]: data?.category || '',
    [FORM_VALUE_ENUM.COMMENT]: '',
    [FORM_VALUE_ENUM.DATE]: '',
    [FORM_VALUE_ENUM.TIME]: '',
  };

  const formik: FormikValues = useFormik({
    enableReinitialize: true,
    initialValues,
    validate,
    onSubmit: (values: FORM_VALUE_INTER | any) => {
      const date = new Date(`${values.date}T${values.time}:00Z`);

      return action.mutate({
        ...values,
        category: values[FORM_VALUE_ENUM.CATEGORY],
        date: date.toISOString(),
        psychologist: data?.user.id,
        price: data?.price,
      });
    },
  });


  const fetchTime = useQuery(
    'time',
    () =>
      actionTime({
        selectedDate: formik.values[FORM_VALUE_ENUM.DATE],
        psychologistId: data?.user.id || '',
      }),
    {
      enabled: !!formik.values[FORM_VALUE_ENUM.DATE],
      onSuccess: (data: string[]) => {
        const option = data.map((hour) => {
          const utcTime = new Date(
            `${formik.values[FORM_VALUE_ENUM.DATE]}T${hour}:00Z`,
          );
          const localTime = new Date(utcTime.getTime() + timezone * 3600000);
          const localHour = String(localTime.getUTCHours()).padStart(2, '0');
          return { label: `${localHour}:00`, value: hour };
        });
        setTimeOption(option);
      },
    },
  );

  useEffect(() => {
    if (activeId) {
      preFetch.refetch();
    }
  }, [activeId]);

  useEffect(() => {
    if (selectedTime && timeSelectFocus) {
      fetchTime.refetch();
    }
  }, [timeSelectFocus]);

  const isFieldError = (name: FORM_VALUE_TYPE): boolean => {
    return formik.errors[name] && formik.touched[name] ? true : false;
  };

  const getFieldError = (name: FORM_VALUE_TYPE): string | undefined =>
    isFieldError(name) ? formik.errors[name] : undefined;

  const isSubmitDisabled = () => {
    if (!formik.isValid) {
      return true;
    }
    if (!formik.dirty) {
      return true;
    }

    if (action.isLoading) {
      return true;
    }
    if (!isLogged()) {
      return true;
    }

    if (!isConfirmEmail()) {
      return true;
    }
  };

  const isFieldDisabled = () => {
    if (!isLogged()) {
      return true;
    }

    if (!isConfirmEmail()) {
      return true;
    }

    if (fetchTime.isLoading) {
      return true;
    }
  };

  const isError = () => {
    if (action.isError && !action.isLoading && getErrorMessage()) {
      return true;
    }
    if (
      fetchTime.isError &&
      !fetchTime.isLoading &&
      !fetchTime.isRefetching &&
      fetchTime.isRefetchError &&
      getErrorMessage()
    ) {
      return true;
    }
  };

  const getErrorMessage = () => {
    const error: ACTION_ERROR_INTER = action.error as ACTION_ERROR_INTER;
    const timeError: ACTION_ERROR_INTER = fetchTime.error as ACTION_ERROR_INTER;

    if (error) {
      return error.message;
    }
    if (timeError) {
      return timeError.message;
    }
  };

  const getFieldValue = (name: FORM_VALUE_TYPE) => formik.values[name];

  const setFieldValue = (name: FORM_VALUE_TYPE, e: SELECT_OPTION_ITEM_DATA) => {
    if (name === FORM_VALUE_ENUM.CATEGORY) {
      setActiveId(e.id);
    }
    if (name === FORM_VALUE_ENUM.DATE) {
      formik.setFieldValue(FORM_VALUE_ENUM.TIME, '');
      setSelectedTime(e.value);
    }

    formik.setFieldValue(name, e.value);
  };

  const setValue = (options: SELECT_OPTION_ITEM_DATA[], value: any) => {
    const optionList = options?.filter(
      (item: SELECT_OPTION_ITEM_DATA) => item.value == formik.values[value],
    )[0];
    return optionList ? optionList : null;
  };

  return (
    <Component
      errorMessage={getErrorMessage()}
      isError={isError()}
      isTimeLoading={fetchTime.isLoading || fetchTime.isRefetching}
      handleSelectFocus={handleSelectFocus}
      timeOption={timeOption}
      timezone={timezone}
      setValue={setValue}
      setFieldValue={setFieldValue}
      isLoading={preFetch.isLoading}
      isSuccess={preFetch.isSuccess}
      data={data}
      formik={formik}
      isFieldError={isFieldError}
      getFieldError={getFieldError}
      isSubmitDisabled={isSubmitDisabled}
      getFieldValue={getFieldValue}
      isLogged={isLogged()}
      isConfirmEmail={isConfirmEmail()}
      isFieldDisabled={isFieldDisabled}
    />
  );
};
