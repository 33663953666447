import VALIDATION from './validation.json';
import ERROR from './error.json';
import AUTH from './auth.json';
import SETTINGS from './settings.json';
import WELLCOME from './wellcome.json';
import COMMON from './common.json';
import NAVIGATION from './navigation.json';
import LANG from './lang.json';
import PROJECT from './project.json';
import OFFICE from './office.json';
import USER from './user.json';
import LANDING from './landing.json';
import CATEGORY from './category.json';
import PSYCHO_LIST from './psycho-list.json';
import PAYMENT from './payment.json';
import ORDER from './order.json';
import SCHEDULE from './schedule.json';

export const UA = {
  translation: {
    VALIDATION,
    SCHEDULE,
    ERROR,
    AUTH,
    SETTINGS,
    WELLCOME,
    COMMON,
    NAVIGATION,
    LANG,
    PROJECT,
    OFFICE,
    USER,
    LANDING,
    CATEGORY,
    PSYCHO_LIST,
    PAYMENT,
    ORDER,
  },
};
