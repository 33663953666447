import { FILE_UPLOAD_INTER } from '../../data/file/constant';
import { CATEGORY_ENUM } from '../profile-psycho-create-category/constant';

export const MODULE_NAME = 'PROFILE_PSYCHO_UPDATE_DATA_MODULE_NAME';

export const ACTION_TYPE = {
  REQUEST_PENDING: `${MODULE_NAME}.REQUEST_PENDING`,
};

export const API = {
  GET: {
    TYPE: 'GET',
    URL: (id?: string) => `consultation/item/${id}`,
  },
};

export interface ACTION_ERROR_INTER {
  error: string;
  message: string;
  statusCode: string;
}

export interface CATEGORY_RAW_INTER {
  id: string;
  category: CATEGORY_ENUM;
  createDate: string;
  description: string;
  updateDate: string;
  video: FILE_UPLOAD_INTER;
  certificate: FILE_UPLOAD_INTER[];
  diploma: FILE_UPLOAD_INTER[];
  price: number;
  duration: number;
}

interface FILE_INTER {
  id: string;
  url: string;
  name: string;
}

interface PSYCHOLOGIST_INTER {
  url: string;
  duration: string;
  name: string;
  aboutMyService: string;
  age: number;
  gender: number;
  methodList: string[];
  methodCustom: string;
  requestList: string[];
  requestCustom: string;
  timezone: string;
  lgbtFrendly: boolean;
  photo: FILE_INTER;
}

export interface DATA_RAW_INTER {
  id: string;
  createDate: string;
  updateDate: string;
  price: string;
  status: string;
  paymentStatus: string;
  date: string;
  comment: string;
  category: number;
  psychologist: PSYCHOLOGIST_INTER;
}

export interface CATEGORY_INTER extends Omit<CATEGORY_RAW_INTER, 'category'> {
  category: string;
}

export interface DATA_INTER
  extends Omit<
    DATA_RAW_INTER,
    'methodList' | 'category' | 'requestList' | 'gender' | 'timezone'
  > {
  methodList: string[];
  requestList: string[];
  gender: string;
  timezone: string;
  category: string;
}
